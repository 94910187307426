.App {
  background-color: #2a2a2a;
  padding: 0 10%;
  width: 100%;
  height: 100vh;
}

h1 {
  font-size: 100px;
  margin-bottom: 0;
  padding-bottom: 0;
}

p {
  font-size: 40px;
  margin-top: 20px;
}

a {
  color: #5e8cf9;
  font-size: 20px;
  margin-bottom: auto;
}

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 50px;
}

img {
  width: 150px;
  height: 150px;
  margin-right: 20px;
  border-radius: 20px;
}

@media screen and (max-width: 830px) {
  h1 {
    font-size: 80px;
  }
}
@media screen and (max-width: 675px) {
  h1 {
    font-size: 60px;
  }

  p {
    font-size: 30px;
  }
}
